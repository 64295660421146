<template>
  <picture>
    <source v-if="this.enableWebp" :srcset="webpSrc" type="image/webp"/>
    <img :src="original"
         alt=""
         :class="classList"/>
  </picture>
</template>

<script>
export default {
  props: {
    enableWebp: {
      required: false,
      default: true,
      type: Boolean
    },
    original: {
      required: true,
      type: String
    },
    classList: {
      required: false,
      default: '',
      type: String
    }
  },

  computed: {
    webpSrc() {
      return this.original.substr(0, this.original.lastIndexOf(".")) + ".webp";
    },
  }
}
</script>

<style scoped>

</style>
