<template>
   <section class="w-full block">
      <hr
         class="w-full h-2 border-0 bg-gradient-to-r from-secondary via-red-900 to-transparent"
      />

      <div
         class="px-4 text-justify lg:text-left lg:px-16 py-8 overflow-y-hidden transition duration-300"
         :class="{
            'h-56': isCollapsed && !minWidth1024,
            'h-96': isCollapsed && minWidth1024,
            'h-auto': !isCollapsed,
         }"
      >
         <h2 class="text-md font-semibold">
            Cristaltec Entertainment è casino, live casino, scommesse, poker e
            bingo
         </h2>

         <div class="my-2">
            <p>
               Cristaltec Entertainment è uno dei migliori fornitori di
               <span class="text-secondary">giochi online</span> grazie alla
               vasta scelta di giochi che mette a disposizione, ai suoi bonus e
               alle sue promozioni.
            </p>
            <p>
               Cristaltec Entertainment offre ai suoi utenti numerosi giochi di
               casino e casino live e poker.
            </p>
            <p>
               Inoltre, presenta una vasta offerta di scommesse sportive online.
               Insomma, con Cristaltec Entertainment il divertimento è
               assicurato!
            </p>
         </div>

         <div class="w-full">
            <h2>Casino Online</h2>

            <div class="w-full flex gap-5">
               <div class="flex-1">
                  <p>
                     Il
                     <a
                        href="#"
                        class="font-semibold text-primary hover:underline"
                        >Casino online</a
                     >
                     di Cristaltec Entertainment è uno dei migliori presenti sul
                     mercato ed è
                     <span class="text-secondary">certificato AAMS</span>,
                     ovvero dai Monopoli di Stato, e quindi legale e sicuro.
                  </p>
                  <p>
                     Potrai vivere le emozioni del casino comodamente da casa
                     tua e vincere ricchi montepremi con pagamenti e prelievi
                     sicuri!
                  </p>
                  <p>
                     Il casino online di Cristaltec Entertainment offre numerosi
                     giochi dai più; classici a quelli più; innovativi.
                  </p>

                  <p class="my-1">Tra i più; popolari troverai:</p>

                  <p>
                     &bull;
                     <a
                        href="#"
                        class="font-semibold text-primary hover:underline"
                        >Roulette Online:</a
                     >
                     scegli il gioco della roulette che ami di più; tra American
                     Roulette, Roulette francese, Roulette inglese e inizia
                     subito a giocare!
                  </p>
                  <p>
                     &bull;
                     <a
                        href="#"
                        class="font-semibold text-primary hover:underline"
                        >Blackjack Online:</a
                     >
                     impara le regole del più; amato gioco di carte dei casinò
                     di tutto il mondo e gioca sui tavoli del nostro casino
                     online!
                  </p>
                  <p>
                     &bull;
                     <a
                        href="#"
                        class="font-semibold text-primary hover:underline"
                        >Slot Machine:</a
                     >
                     gioca su Cristaltec Entertainment alle migliori e più;
                     popolari slot machine. Dai grandi giochi classici come Fowl
                     Play Gold, la popolarissima Slot Gallina, agli ultimi
                     titoli a disposizione.
                  </p>
                  <p>
                     &bull;
                     <a
                        href="#"
                        class="font-semibold text-primary hover:underline"
                        >Slot da Bar:</a
                     >
                     gioca su Cristaltec Entertainment alle slot machine più;
                     amate per il loro stile classico. Questi giochi, ora
                     disponibili anche online, sono presenti nei megliori casinò
                     terrestri del mondo.
                  </p>
               </div>
            </div>
         </div>

         <div class="w-full mt-4">
            <h2>Live Casino</h2>

            <div class="w-full flex gap-5">
               <a href="#"><img alt="" src="" /></a>
               <div class="flex-1">
                  <p>
                     Grazie al
                     <a
                        href="#"
                        class="font-semibold text-primary hover:underline"
                        >Live Casino online</a
                     >
                     di Cristaltec Entertainment, autorizzato AAMS, quindi dai
                     Monopoli di Stato, vivrai le emozioni del gioco dal vivo,
                     trovandoti in diretta streaming nelle migliori sale da
                     gioco del mondo.
                  </p>
                  <p>
                     Potrai giocare live a tutti i giochi presenti in un casino
                     terrestre come la
                     <a
                        href="#"
                        class="font-semibold text-primary hover:underline"
                        >Roulette</a
                     >, il
                     <a
                        href="#"
                        class="font-semibold text-primary hover:underline"
                        >Blackjack</a
                     >, il
                     <a
                        href="#"
                        class="font-semibold text-primary hover:underline"
                        >Baccarat</a
                     >
                     e il
                     <a
                        href="#"
                        class="font-semibold text-primary hover:underline"
                        >Poker</a
                     >, comodamente da casa tua ma vivendo le stesse emozioni
                     che si provano ad essere in un vero casino!
                  </p>
               </div>
            </div>
         </div>

         <div class="w-full mt-4">
            <h2>Poker Online</h2>

            <div class="w-full flex gap-5">
               <a href="#" class="font-semibold text-primary hover:underline"
                  ><img alt="" src=""
               /></a>
               <div class="flex-1">
                  <p>
                     Gioca al
                     <a
                        href="#"
                        class="font-semibold text-primary hover:underline"
                        >Poker online</a
                     >
                     di Cristaltec Entertainment: è legale e sicuro in quanto
                     <span class="text-secondary">certificato AAMS</span>.
                  </p>
                  <p>
                     Il <span class="text-secondary">Poker Online</span> si
                     differenzia da altri giochi online in quanto l'abilità del
                     giocatore è determinante per vincere una partita: saper
                     osservare il comportamento degli altri giocatori per
                     intuire il loro punto e cercare di indurli in errore fa la
                     differenza nell'arco di una partita.
                  </p>
                  <p>
                     Ma non dimenticare: la fortuna vuole sempre la sua parte!
                  </p>
                  <p>
                     Quindi prima di iniziare a giocare al poker online è
                     importante imparare le regole del poker e quindi il valore
                     delle carte del poker.
                  </p>
               </div>
            </div>
         </div>

         <div class="w-full mt-4">
            <h2>Scommesse Sportive Online</h2>

            <div class="w-full flex gap-5">
               <a href="#" class="font-semibold text-primary hover:underline"
                  ><img alt="" src=""
               /></a>
               <div class="flex-1">
                  <p>
                     Cristaltec Entertainment ti dà la possibilità di fare le
                     tue
                     <a
                        href="#"
                        class="font-semibold text-primary hover:underline"
                        >Scommesse Sportive Online</a
                     >
                     su tutte le competizioni sportive che si tengono nel mondo.
                  </p>
                  <p>
                     Scommetti direttamente dal tuo iPhone o smartphone grazie
                     all'<a
                        href=""
                        class="font-semibold text-primary hover:underline"
                        >APP Scommesse Cristaltec Entertainment</a
                     >: farai le tue puntate in qualsiasi momento ovunque ti
                     trovi!
                  </p>
                  <p>
                     Su Cristaltec Entertainment potrai fare scommesse di
                     calcio, scommesse di tennis, scommesse di basket, motori,
                     pallavolo, ippica nazionale e tante altre scommesse
                     sportive anche in diretta live grazie all'offerta di
                     scommesse del palinsesto dal vivo.
                  </p>
                  <p>
                     Inoltre non perderti le scommesse sui
                     <a
                        href="#"
                        class="font-semibold text-primary hover:underline"
                        >Virtual Games</a
                     >!
                  </p>
               </div>
            </div>
         </div>

         <div class="w-full mt-4">
            <h2>Gioca ai giochi Merkur direttamente dal tuo telefonino</h2>

            <div class="w-full">
               <p>
                  I giochi Cristaltec Entertainment li puoi avere sempre a
                  portata di mano grazie alla
                  <span class="text-secondary">versione mobile</span>!
               </p>
               <p>
                  Così potrai giocare direttamente dal tuo telefonino o tablet
                  ovunque ti trovi.
               </p>
               <p>
                  Potrai giocare a tutti i giochi offerti da Cristaltec
                  Entertainment: casino online, casino live, poker e scommesse.
               </p>
               <p>
                  Prova subito i nostri giochi dal tuo cellulare o tablet: ti
                  offriamo una delle migliori esperienze di gioco mobile.
               </p>
            </div>
         </div>

         <div class="w-full mt-4">
            <h2>Blog</h2>

            <div class="w-full">
               <p>
                  Vuoi essere sempre aggiornato sulle novità del
                  <span class="text-secondary">nostro Casinò</span>?
               </p>
               <p>
                  O vuoi conoscere tutti i trucchi per vincere alle
                  <span class="text-secondary">slot machine</span> o per
                  compilare una
                  <span class="text-secondary">schedina vincente</span>?
               </p>
               <p>
                  Visita il nostro<a
                     href="#"
                     class="font-semibold text-primary hover:underline"
                     >blog</a
                  >
                  per non perderti nessuna novità!
               </p>
            </div>
         </div>

         <div class="w-full mt-4">
            <h2>Giochi online sicuri</h2>

            <div class="w-full">
               <p>
                  Il sito di
                  <span class="text-secondary">Cristaltec Entertainment</span> è
                  raggiungibile attraverso un
                  <span class="text-secondary">canale sicuro SSL</span> criptato
                  che garantisce la riservatezza delle informazioni scambiate
                  tra l'utente ed il sito.
               </p>
               <p>
                  Potrai così effettuare tutte le transazioni bancarie senza il
                  minimo rischio.
               </p>
               <p>
                  Cristaltec Entertainment possiede una
                  <span class="text-secondary">licenza di gioco legale</span>,
                  ottenuta dopo accurati controlli da parte di AAMS, l'agenzia
                  delle Dogane e dei Monopoli.
               </p>
               <p>
                  Dispone di un software certificato in modo da garantire
                  assoluta equità, la massima sicurezza nelle transazioni
                  bancarie e la piena tutela della privacy dei giocatori.
               </p>
            </div>
         </div>

         <div class="w-full mt-4">
            <h2>Assistenza clienti</h2>

            <div class="w-full">
               <p>
                  Cristaltec Entertainment è sempre a fianco dei suoi clienti:
                  sia nuovi che fidelizzati.
               </p>
               <p>
                  Un team di professionisti italiani è sempre in grado di
                  affiancarti per ogni tua necessità: dubbi sul funzionamento di
                  un gioco, problemi al momento dell'iscrizione, come incassare
                  una vincita ecc&hellip;
               </p>
               <p>
                  Potrai ricevere assistenza telefonica al numero 02 47 92 77 70
                  oppure scrivendo all'indirizzo e-mail
                  <a href="#">supporto@merkurwin.it</a>.
               </p>
               <p>Il servizio è attivo dalle 10.00 alle 17.00.</p>
            </div>
         </div>
      </div>

      <div class="w-full py-3 text-center">
         <button
            class="bg-secondary uppercase text-gray-900 rounded-full text-xs font-semibold py-1 px-4"
            @click="isCollapsed = !isCollapsed"
         >
            {{ isCollapsed ? "Vede più" : "Vede meno" }}
         </button>
      </div>
   </section>
</template>

<script>
export default {
   name: "LegalInformation",

   data() {
      return {
         isCollapsed: true,
         minWidth1024: window.innerWidth >= 1024,
      };
   },
};
</script>

<style scoped lang="postcss">
section p {
   @apply text-xs;
}
</style>
