<template>
   <div class="w-full bg-gray-900 px-4 py-1.5 lg:py-3 flex items-center justify-center lg:justify-between">
      <form action="" class="flex-1 hidden lg:flex items-center">
         <button type="submit">
            <svg
               class="w-10 h-10"
               fill="none"
               stroke="currentColor"
               viewBox="0 0 24 24"
            >
               <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="{2}"
                  d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
               />
            </svg>
         </button>

         <input
            type="text"
            class="px-2 flex-1 border-b-2 border-white bg-transparent focus:outline-none"
         />
      </form>

      <div class="ml-5 hidden lg:flex items-center justify-between gap-3">
         <button type="button">
            <svg
               class="w-8 h-8"
               fill="none"
               stroke="currentColor"
               viewBox="0 0 24 24"
            >
               <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="{2}"
                  d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
               />
            </svg>
         </button>

         <button
            class="px-3 py-1 rounded-full uppercase text-sm bg-secondary border-2 border-secondary text-black hover:bg-transparent hover:text-secondary"
         >
            Registrazione
         </button>

         <button
            class="px-3 py-1 rounded-full uppercase text-sm border-2 border-white text-white hover:bg-primary hover:border-primary hover:text-black"
         >
            Accesso
         </button>
      </div>

      <custom-image
         original="/img/CRISTALTEC_ENTERTAINMENT.png"
         :enable-webp="false"
         class-list="h-6 lg:h-12 object-contain lg:hidden"
      />
   </div>
</template>

<script>
export default {
  name: "TopBar"
}
</script>

<style scoped></style>
