import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "swiper/swiper-bundle.min.css";
// import SwiperCore, { Navigation, Pagination } from "swiper/core";

// Global Components
import CustomImage from '@/components/utils/CustomImage'
Vue.component('custom-image', CustomImage)

Vue.config.productionTip = false
// SwiperCore.use([Navigation, Pagination]);

Vue.filter('money',
  (value) => {
    if (!value) {
      return ''
    }
    return new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR', maximumFractionDigits: 0}).format(value)
  }
)

import gamesList from "@/gamesList";
import barsPerRegion from "@/barsPerRegion";

new Vue({
  router,
  render: h => h(App),
  data() {
    return {
      games: [],
      barsPerRegion: [],
      barSigned: null
    }
  },

  watch: {
    barsPerRegion: {
      deep: true,
      handler() {
        console.log('data changed')
        this.updateLocalStorageWithGlimpseData();
        this.$forceUpdate();
      }
    }
  },

  created() {
    if("games" in localStorage && "barsPerRegion" in localStorage){
      this.games = JSON.parse(localStorage.getItem("games"));
      this.barsPerRegion = JSON.parse(localStorage.getItem('barsPerRegion'))
      console.log("success getting localstorage")
    }else {
      console.log('error getting localstorage')
      this.games = gamesList;
      this.barsPerRegion = barsPerRegion;
    }
  },

  methods: {
    updateLocalStorageWithGlimpseData() {
      localStorage.setItem('games', JSON.stringify(this.games));
      localStorage.setItem('barsPerRegion', JSON.stringify(this.barsPerRegion));
      console.log("saved data to localstorage")
    }
  }
}).$mount('#app')
