<template>
   <div ref="footer" id="footer" class="w-full mt-10 bg-gray-800">
      <LegalInformation v-if="$route.path === '/'" />

      <section class="w-full">
         <hr
            class="w-full h-2 border-0 bg-gradient-to-r from-secondary via-red-900 to-transparent"
         />
         <div class="w-full p-4 lg:px-16 lg:py-10">
            <div
               class="w-full inline-flex items-center justify-center divide-x divide-secondary mb-10"
               v-if="minWidth1024"
            >
               <a
                  href="#"
                  class="px-4 leading-none text-secondary text-sm cursor-pointer hover:underline"
                  >Gioco Responsabile</a
               >
               <a
                  href="#"
                  class="px-4 leading-none text-secondary text-sm cursor-pointer hover:underline"
                  >Regole</a
               >
               <a
                  href="#"
                  class="px-4 leading-none text-secondary text-sm cursor-pointer hover:underline"
                  >Chi Siamo</a
               >
               <a
                  href="#"
                  class="px-4 leading-none text-secondary text-sm cursor-pointer hover:underline"
                  >Mobile Apps</a
               >
               <a
                  href="#"
                  class="px-4 leading-none text-secondary text-sm cursor-pointer hover:underline"
                  >Aiuto e Supporto</a
               >
               <a
                  href="#"
                  class="px-4 leading-none text-secondary text-sm cursor-pointer hover:underline"
                  >Cookie</a
               >
               <a
                  href="#"
                  class="px-4 leading-none text-secondary text-sm cursor-pointer hover:underline"
                  >Promozioni</a
               >
               <a
                  href="#"
                  class="px-4 leading-none text-secondary text-sm cursor-pointer hover:underline"
                  >Affiliazione</a
               >
               <a
                  href="#"
                  class="px-4 leading-none text-secondary text-sm cursor-pointer hover:underline"
                  >Privacy</a
               >
            </div>
            <div
               class="w-full grid grid-cols-3 gap-x-2 gap-y-4 text-center mb-10"
               v-else
            >
               <a
                  href="#"
                  class="p-4 flex items-center justify-center leading-none text-secondary text-sm bg-gray-700 bg-opacity-60 rounded-lg"
                  >Gioco Responsabile</a
               >
               <a
                  href="#"
                  class="p-4 flex items-center justify-center leading-none text-secondary text-sm bg-gray-700 bg-opacity-60 rounded-lg"
                  >Regole</a
               >
               <a
                  href="#"
                  class="p-4 flex items-center justify-center leading-none text-secondary text-sm bg-gray-700 bg-opacity-60 rounded-lg"
                  >Chi Siamo</a
               >
               <a
                  href="#"
                  class="p-4 flex items-center justify-center leading-none text-secondary text-sm bg-gray-700 bg-opacity-60 rounded-lg"
                  >Mobile Apps</a
               >
               <a
                  href="#"
                  class="p-4 flex items-center justify-center leading-none text-secondary text-sm bg-gray-700 bg-opacity-60 rounded-lg"
                  >Aiuto e Supporto</a
               >
               <a
                  href="#"
                  class="p-4 flex items-center justify-center leading-none text-secondary text-sm bg-gray-700 bg-opacity-60 rounded-lg"
                  >Cookie</a
               >
               <a
                  href="#"
                  class="p-4 flex items-center justify-center leading-none text-secondary text-sm bg-gray-700 bg-opacity-60 rounded-lg"
                  >Promozioni</a
               >
               <a
                  href="#"
                  class="p-4 flex items-center justify-center leading-none text-secondary text-sm bg-gray-700 bg-opacity-60 rounded-lg"
                  >Affiliazione</a
               >
               <a
                  href="#"
                  class="p-4 flex items-center justify-center leading-none text-secondary text-sm bg-gray-700 bg-opacity-60 rounded-lg"
                  >Privacy</a
               >
            </div>

            <p class="leading-none text-center text-sm mb-12">
               &copy; {{ new Date().getFullYear() }} | CRISTALTEC ENTERTAINMENT
               ITALIA S.P.A. con socio unico | Società soggetta ad attività di
               direzione e coordinamento esercitata del socio unico Merkur
               Interative GmbH P.IVA 02719361202 | GAD 15099
            </p>

            <div
               class="w-full inline-flex items-center justify-center gap-4 mb-10"
            >
               <a class="cursor-pointer" href="#"
                  ><custom-image
                     class-list="h-10"
                     original="/img/icons/legal-logos/siae.png"
               /></a>
               <a class="cursor-pointer" href="#"
                  ><custom-image
                     class-list="h-10"
                     original="/img/icons/legal-logos/adm_2020.png"
               /></a>
               <a class="cursor-pointer" href="#"
                  ><custom-image
                     class-list="h-10"
                     original="/img/icons/legal-logos/adm.png"
               /></a>
               <a class="cursor-pointer" href="#"
                  ><custom-image
                     class-list="h-10"
                     original="/img/icons/legal-logos/gioca_giusto.png"
               /></a>
               <a class="cursor-pointer" href="#"
                  ><custom-image
                     class-list="h-10"
                     original="/img/icons/legal-logos/18+.png"
               /></a>
            </div>

            <div
               class="w-full inline-flex items-center justify-center gap-2 mb-12"
            >
               <custom-image
                  class-list="h-10"
                  original="/img/icons/payment/DINERS_CLUB.png"
               />
               <custom-image
                  class-list="h-10"
                  original="/img/icons/payment/JCB.png"
               />
               <custom-image
                  class-list="h-10"
                  original="/img/icons/payment/MAESTRO.png"
               />
               <custom-image
                  class-list="h-10"
                  original="/img/icons/payment/MASTERCARD.png"
               />
               <custom-image
                  class-list="h-10"
                  original="/img/icons/payment/PAYPAL.png"
               />
               <custom-image
                  class-list="h-10"
                  original="/img/icons/payment/POSTEPAY.png"
               />
               <custom-image
                  class-list="h-10"
                  original="/img/icons/payment/VISA.png"
               />
               <custom-image
                  class-list="h-10"
                  original="/img/icons/payment/VISA_ELECTRON.png"
               />
            </div>
         </div>
      </section>
   </div>
</template>

<script>
import LegalInformation from "@/components/LegalInformation";

export default {
   name: "Footer",

   data() {
      return {
         minWidth1024: window.innerWidth >= 1024,
      };
   },

   components: {
      LegalInformation,
   },

   mounted() {
      window.addEventListener("resize", this.updateMinWidth1024);
   },

   methods: {
      updateMinWidth1024() {
         this.minWidth1024 = window.innerWidth >= 1024;
      },
   },
};
</script>
