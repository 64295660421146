export default {
	'Lazio': [
		{
			name: 'Bar Gioran',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/glimpse/gioran.jpg',
			in: 226457,
			out: 198794,
			jackpot: 12398,
			coordinates: {
				lat: 41.9456772,
				lng: 12.5194714
			},
			address: "Via Gioran",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/gioran/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/gioran/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/gioran/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Capanelle',
			totalPrize: '387954',
			numberGames: 13,
			image: '/img/glimpse/capanelle.jpg',
			in: 235168,
			out: 218395,
			jackpot: 12398,
			coordinates: {
				lat: 41.836814588295915,
				lng: 12.58417958294624
			},
			address: "Via Capanelle",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_money_storm.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 1,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_optimus_prize.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 2,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 4,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/capanelle/green.jpg',
					selected: false
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/capanelle/red.jpg',
					selected: true
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/capanelle/blue.jpg',
					selected: false
				}
			]

		},
		{
			name: 'Bar Giulia',
			totalPrize: '342168',
			numberGames: 13,
			image: '/img/glimpse/giulia.jpg',
			in: 187982,
			out: 178049,
			jackpot: 12398,
			coordinates: {
				lat: 41.8992964,
				lng: 12.4654786
			},
			address: "Via Giulia",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_wild_stars.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 5,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_money_storm.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 1,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character2.png',
					freeToPlay: false,
					in: 2204,
					out: 1745,
					game: 4,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 4,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/giulia/green.jpg',
					selected: false
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/giulia/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/giulia/blue.jpg',
					selected: true
				}
			]
		}
	],
	'Veneto': [
		{
			name: 'Bar Telliera',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/glimpse/telliera.jpg',
			in: 23777,
			out: 14024,
			jackpot: 12398,
			coordinates: {
				lat: 45.8829592,
				lng: 12.149303
			},
			address: "Via Telliera",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 3584,
					out: 1745,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					freeToPlay: true,
					in: 8761,
					out: 6952,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 3214,
					out: 1862,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 4593,
					out: 2478,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 3625,
					out: 987,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/telliera/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/telliera/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/telliera/blue.jpg',
					selected: false
				}
			]
		},
		{
			name: 'Bar Tucano',
			totalPrize: '502357',
			numberGames: 13,
			image: '/img/glimpse/tucano.jpg',
			in: 14688,
			out: 8450,
			jackpot: 12398,
			coordinates: {
				lat: 45.5557159,
				lng: 11.5353293
			},
			address: "Via Tucano",
			cabinets: [
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_golden_fever.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character1.png',
					freeToPlay: false,
					in: 3045,
					out: 1123,
					game: 4,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_power_up.png',
					playing: '/img/glimpse/cabinets/glamour/glamour_character2.png',
					freeToPlay: false,
					in: 4781,
					out: 3645,
					game: 2,
				},
				{
					type: 'awp',
					img: '/img/glimpse/cabinets/glamour/glamour_triple_star.png',
					freeToPlay: true,
					in: 1674,
					out: 450,
					game: 3,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					playing: '/img/glimpse/cabinets/VLT/vlt_character2.png',
					freeToPlay: false,
					in: 2984,
					out: 1487,
					game: 0,
				},
				{
					type: 'vlt',
					img: '/img/glimpse/cabinets/VLT/vlt_base.png',
					freeToPlay: true,
					in: 2204,
					out: 1745,
					game: 0,
				}
			],
			cabinetsFree: 2,
			availableWallpapers: [
				{
					slug: 'verde',
					preview: '/img/glimpse/salas/wallpapers/green.jpg',
					bg: '/img/glimpse/salas/tucano/green.jpg',
					selected: true
				},
				{
					slug: 'rosso',
					preview: '/img/glimpse/salas/wallpapers/red.jpg',
					bg: '/img/glimpse/salas/tucano/red.jpg',
					selected: false
				},
				{
					slug: 'blu',
					preview: '/img/glimpse/salas/wallpapers/blue.jpg',
					bg: '/img/glimpse/salas/tucano/blue.jpg',
					selected: false
				}
			]
		},
	]
}
