<template>
   <div>
      <button
         id="menu-button"
         @click="isCollapsed = !isCollapsed"
         :class="{ 'is-menu-open': isCollapsed }"
      >
         <svg
            class="w-8 h-8 fill-current transition-all duration-300 delay-100"
            :class="{ 'is-menu-open': !isCollapsed }"
            viewBox="0 0 20 20"
         >
            <path
               fill-rule="evenodd"
               class="text-primary"
               d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
               clip-rule="evenodd"
            />
            <path
               fill-rule="evenodd"
               class="text-secondary"
               d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
               clip-rule="evenodd"
            />
         </svg>

         <span class="uppercase text-xs font-semibold leading-tight">Menu</span>
      </button>

      <div id="menu" :class="{ 'is-closed': isCollapsed }">
         <div class="h-8 lg:h-40 lg:p-8">
            <router-link :to="{ name: 'Home' }">
               <!-- <custom-image original="/img/CRISTALTEC_ENTERTAINMENT.png" :enable-webp="false"
               class-list="h-full w-full object-contain"/> -->
               <!-- <video
                  src="/img/glimpse/rgi.mp4"
                  class="h-full w-fit object-contain"
                  autoplay
                  loop
                  muted
               ></video> -->
               <!-- < -->
            </router-link>
         </div>

         <div class="flex-1 flex flex-col gap-y-5 lg:gap-5 px-6 lg:px-10">
            <div class="flex justify-center">
               <custom-image
                  original="/img/avatar.png"
                  class-list="h-24 w-24 p-1 lg:w-32 lg:h-32 lg:p-2 object-contain bg-gray-600 rounded-full shadow-xl"
               />
            </div>
            <!--        <button
            class="py-2 rounded-full uppercase text-xl border-2 border-primary text-primary hover:bg-primary hover:text-black">
          Accesso
        </button>-->
            <h1
               class="-mt-3 lg:py-2 rounded-full uppercase text-lg lg:text-xl text-primary text-center w-full"
            >
               Giovanni
            </h1>

            <nav class="flex flex-col items-stretch gap-1">
               <router-link
                  class="navigation-link-extended"
                  :to="{ name: 'Promotions' }"
                  >Promozione</router-link
               >
            </nav>
            <nav class="flex flex-col items-stretch gap-1">
               <router-link
                  v-on:click.native="closeMenu()"
                  class="navigation-link-extended"
                  :to="{ name: 'Home' }"
                  >Home</router-link
               >
               <router-link
                  v-on:click.native="closeMenu()"
                  class="navigation-link-extended"
                  :to="{ name: 'SlotsBar' }"
                  >Slots Bar</router-link
               >
               <router-link
                  v-on:click.native="closeMenu()"
                  class="navigation-link-extended"
                  :to="{ name: 'SlotsVlt' }"
                  >Slots VLT</router-link
               >
               <router-link
                  v-on:click.native="closeMenu()"
                  class="navigation-link-extended"
                  :to="{ name: 'Bets' }"
                  >Scommesse Sportive</router-link
               >
               <router-link
                  v-on:click.native="closeMenu()"
                  class="navigation-link-extended"
                  :to="{ name: 'CasinoLive' }"
                  >Casino Live</router-link
               >
               <router-link
                  v-on:click.native="closeMenu()"
                  class="navigation-link-extended"
                  :to="{ name: 'OnlineGaming' }"
                  >Giochi Online</router-link
               >
               <router-link
                  v-on:click.native="closeMenu()"
                  class="navigation-link-extended"
                  :to="{ name: 'Lottery' }"
                  >Lotteria</router-link
               >
            </nav>
            <nav class="flex flex-col items-stretch gap-1">
               <router-link
                  v-on:click.native="closeMenu()"
                  class="navigation-link-extended"
                  :to="{ name: 'About' }"
                  >Cristaltec Entertainment</router-link
               >
               <router-link
                  v-on:click.native="closeMenu()"
                  class="navigation-link-extended"
                  :to="{ name: 'Chat' }"
                  >Chat</router-link
               >
            </nav>
            <nav class="flex flex-col items-stretch gap-1">
               <router-link
                  v-on:click.native="closeMenu()"
                  target="_blank"
                  class="navigation-link-extended"
                  :to="{ name: 'GlimpseManagerLogin' }"
                  >Area Riservata</router-link
               >
            </nav>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "MainSidebar",

   props: {
      windowW: Number,
   },

   data() {
      return {
         isCollapsed: true,
      };
   },

   methods: {
      closeMenu() {
         if (this.windowW < 1024) {
            this.isCollapsed = true;
         }
      },
   },
};
</script>

<style lang="postcss" scoped>
a.navigation-link-extended {
   @apply bg-gray-600 rounded-lg py-2 px-4 uppercase text-xs font-semibold;

   &:hover:not(.router-link-exact-active) {
      @apply bg-secondary text-black;
   }

   &.router-link-exact-active {
      @apply bg-primary text-black;
   }
}

#menu {
   @apply flex-shrink-0 p-4 h-screen overflow-y-auto lg:overflow-hidden bg-gray-900 text-white flex flex-col items-stretch justify-between lg:gap-5 transition-all duration-150 shadow-2xl w-screen;

   &.is-closed {
      @apply h-0 overflow-hidden p-0;
   }

   @screen lg {
      @apply w-72;

      &.is-closed {
         @apply h-screen w-0;
      }
   }
}

#menu-button {
   right: 5%;
   bottom: 0;
   @apply w-fit z-50 absolute p-2 bg-gray-900 shadow-2xl rounded-t-xl flex flex-col items-center gap-1;

   svg {
      @apply transform -rotate-90;

      &.is-menu-open {
         @apply transform -rotate-90 -scale-x-1;
      }
   }

   @screen lg {
      @apply rounded-t-none rounded-r-xl transition duration-300 w-fit;

      bottom: 70%;
      left: 18rem;

      &.is-menu-open {
         left: 0;
      }

      svg {
         @apply transform rotate-0;

         &.is-menu-open {
            @apply transform -scale-x-1;
         }
      }
   }
}
</style>
