import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/slots-bar',
    name: 'SlotsBar',
    // component: () => import(/* webpackChunkName: "slotsBar" */ '../views/SlotsBar.vue')
    component: () => import(/* webpackChunkName: "slotsBar" */ '../views/Glimpse.vue')
  },
  {
    path: '/slots-vlt',
    name: 'SlotsVlt',
    component: () => import(/* webpackChunkName: "slotsVlt" */ '../views/SlotsVlt.vue')
  },
  {
    path: '/bets',
    name: 'Bets',
    component: () => import(/* webpackChunkName: "bets" */ '../views/Bets.vue')
  },
  {
    path: '/casino',
    name: 'CasinoLive',
    component: () => import(/* webpackChunkName: "casinoLive" */ '../views/CasinoLive.vue')
  },
  {
    path: '/online',
    name: 'OnlineGaming',
    component: () => import(/* webpackChunkName: "onlineGaming" */ '../views/OnlineGaming.vue')
  },
  {
    path: '/lottery',
    name: 'Lottery',
    component: () => import(/* webpackChunkName: "lottery" */ '../views/Lottery.vue')
  },
  {
    path: '/promotions',
    name: 'Promotions',
  },
  {
    path: '/chat',
    name: 'Chat',
  },
  {
    path: '/about',
    name: 'About',
  },
  {
    path: '/manager',
    component: () => import('../views/GlimpseManager/Layout.vue'),
    children: [
      {
        path: 'reset',
        name: 'Reset',
        component: () => import(/* webpackChunkName: "lottery" */ '../views/GlimpseManager/Reset.vue')
      },
      {
        path: 'login',
        name: 'GlimpseManagerLogin',
        component: () => import(/* webpackChunkName: "lottery" */ '../views/GlimpseManager/Login.vue')
      },
      {
        path: 'bars',
        name: 'GlimpseManagerBars',
        component: () => import(/* webpackChunkName: "lottery" */ '../views/GlimpseManager/Bars.vue')
      },
      {
        path: 'bars/:region/:bar',
        name: 'GlimpseManagerBar',
        component: () => import(/* webpackChunkName: "lottery" */ '../views/GlimpseManager/Bar.vue')
      },
      {
        path: 'bars/:region/:bar/cabinets/:cabinet',
        name: 'GlimpseManagerBarCabinet',
        component: () => import(/* webpackChunkName: "lottery" */ '../views/GlimpseManager/BarCabinet.vue')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
