export default {
	'vlt': [
		{
			name: 'vlt',
			slug: 'vlt',
			image: '/img/glimpse/games/vlt/vlto/splash.jpg',
			menu: [
				'/img/glimpse/games/vlt/vlto/menu_0.jpg',
				'/img/glimpse/games/vlt/vlto/menu_1.jpg',
			],
		},
	],
	'awp': [
		{
			name: 'Optimus Prize',
			slug: 'optimus_prize',
			image: '/img/glimpse/games/awp/optimus_prize/splash.jpg',
			menu: [
				'/img/glimpse/games/awp/optimus_prize/menu_0.jpg',
				'/img/glimpse/games/awp/optimus_prize/menu_1.jpg',
				'/img/glimpse/games/awp/optimus_prize/menu_2.jpg',
				'/img/glimpse/games/awp/optimus_prize/menu_3.jpg',
				'/img/glimpse/games/awp/optimus_prize/menu_4.jpg'
			],
			games: [
				'/img/glimpse/games/awp/optimus_prize/game_0.jpg',
				'/img/glimpse/games/awp/optimus_prize/game_1.jpg',
				'/img/glimpse/games/awp/optimus_prize/game_2.jpg',
				'/img/glimpse/games/awp/optimus_prize/game_3.jpg',
				'/img/glimpse/games/awp/optimus_prize/game_4.jpg'
			],
		},
		{
			name: 'Money Storm',
			slug: 'money_storm',
			image: '/img/glimpse/games/awp/money_storm/splash.jpg',
			menu: [
				'/img/glimpse/games/awp/money_storm/menu_0.jpg',
				'/img/glimpse/games/awp/money_storm/menu_1.jpg',
				'/img/glimpse/games/awp/money_storm/menu_2.jpg',
				'/img/glimpse/games/awp/money_storm/menu_3.jpg',
				'/img/glimpse/games/awp/money_storm/menu_4.jpg',
			],
			games: [
				'/img/glimpse/games/awp/money_storm/game_0.jpg',
				'/img/glimpse/games/awp/money_storm/game_1.jpg',
				'/img/glimpse/games/awp/money_storm/game_2.jpg',
				'/img/glimpse/games/awp/money_storm/game_3.jpg',
				'/img/glimpse/games/awp/money_storm/game_4.jpg',
			],
		},
		{
			name: 'Power Up',
			slug: 'power_up',
			image: '/img/glimpse/games/awp/power_up/splash.jpg',
			menu: [
				'/img/glimpse/games/awp/power_up/menu_0.jpg',
				'/img/glimpse/games/awp/power_up/menu_1.jpg',
				'/img/glimpse/games/awp/power_up/menu_2.jpg',
				'/img/glimpse/games/awp/power_up/menu_3.jpg',
				'/img/glimpse/games/awp/power_up/menu_4.jpg',
			],
			games: [
				'/img/glimpse/games/awp/power_up/game_0.jpg',
				'/img/glimpse/games/awp/power_up/game_1.jpg',
				'/img/glimpse/games/awp/power_up/game_2.jpg',
				'/img/glimpse/games/awp/power_up/game_3.jpg',
				'/img/glimpse/games/awp/power_up/game_4.jpg',
			],
		},
		{
			name: 'Triple Star',
			slug: 'triple_star',
			image: '/img/glimpse/games/awp/triple_star/splash.jpg',
			menu: [
				'/img/glimpse/games/awp/triple_star/menu_0.jpg',
				'/img/glimpse/games/awp/triple_star/menu_1.jpg',
				'/img/glimpse/games/awp/triple_star/menu_2.jpg',
			],
			games: [
				'/img/glimpse/games/awp/triple_star/game_0.jpg',
				'/img/glimpse/games/awp/triple_star/game_1.jpg',
				'/img/glimpse/games/awp/triple_star/game_2.jpg',
			],
		},
		{
			name: 'Golden Fever',
			slug: 'golden_fever',
			image: '/img/glimpse/games/awp/golden_fever/splash.jpg',
			menu: [
				'/img/glimpse/games/awp/golden_fever/menu_0.jpg',
				'/img/glimpse/games/awp/golden_fever/menu_1.jpg',
				'/img/glimpse/games/awp/golden_fever/menu_2.jpg',
				'/img/glimpse/games/awp/golden_fever/menu_3.jpg',
				'/img/glimpse/games/awp/golden_fever/menu_4.jpg',
			],
			games: [
				'/img/glimpse/games/awp/golden_fever/game_0.jpg',
				'/img/glimpse/games/awp/golden_fever/game_1.jpg',
				'/img/glimpse/games/awp/golden_fever/game_2.jpg',
				'/img/glimpse/games/awp/golden_fever/game_3.jpg',
				'/img/glimpse/games/awp/golden_fever/game_4.jpg',
			],
		},
		{
			name: 'Wild Stars',
			slug: 'wild_stars',
			image: '/img/glimpse/games/awp/wild_stars/splash.jpg',
			menu: [
				'/img/glimpse/games/awp/wild_stars/menu_0.jpg',
				'/img/glimpse/games/awp/wild_stars/menu_1.jpg',
				'/img/glimpse/games/awp/wild_stars/menu_2.jpg',
				'/img/glimpse/games/awp/wild_stars/menu_3.jpg',
				'/img/glimpse/games/awp/wild_stars/menu_4.jpg',
			],
			games: [
				'/img/glimpse/games/awp/wild_stars/game_0.jpg',
				'/img/glimpse/games/awp/wild_stars/game_1.jpg',
				'/img/glimpse/games/awp/wild_stars/game_2.jpg',
				'/img/glimpse/games/awp/wild_stars/game_3.jpg',
				'/img/glimpse/games/awp/wild_stars/game_4.jpg',
			],
		},
	]
}
